import { NextPageContext } from 'next'

/** Определяет по юзер-агенту, мобила сейчас или десктоп */
export const isTouch = async (ctx: NextPageContext): Promise<boolean> => {
  const headers = ctx && ctx.req && ctx.req.headers

  const isMobile = headers
    ? headers.viewport === 'mobile'
    : (navigator as any)?.userAgentData?.mobile

  return Boolean(isMobile)
}
